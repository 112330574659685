import React, { useMemo } from 'react'
import { Box, BoxProps, Flex, Heading, Image } from '@chakra-ui/react'
import { getPreviewRenderItems, getRenderItems, Layout, RenderItem } from '../domain/layout'
import { LayoutHelper } from '../helpers/layoutHelper'
import { getRealPicturePath, getTextColor, Template } from '../domain/template'
import { FormattedMessage } from 'react-intl'
import { Client } from '../domain/client'
import { TextOrPicture } from './TextOrPicture'
import Gift from '../domain/gift'

export type LayoutCardProps = BoxProps & {
  gift: Gift
  template: Template
  layout: Layout
  sender?: Client
  index?: number
}

export default function LayoutCard({
  gift,
  template,
  layout,
  sender,
  ...props
}: LayoutCardProps): React.JSX.Element {
  const renderItems: RenderItem[] =
    layout.role !== 'PREVIEW' ? getRenderItems(layout) : getPreviewRenderItems(gift)
  const picturesCount = useMemo(
    () => renderItems.filter(r => r.type === 'picture').length,
    [renderItems]
  )

  const containerSizeByPictureCount = ['xl', 'lg', 'md', 'sm']

  return (
    <Flex
      className={`layout-card ${layout.role.toLocaleLowerCase()} ${
        (picturesCount > 0 && 'img-container') || undefined
      } ${(picturesCount > 0 && containerSizeByPictureCount[picturesCount - 1]) || undefined}`}
      backgroundImage={`url("${process.env.REACT_APP_API_URL}/${getRealPicturePath(
        template,
        layout.usesDarkMode
      )}")`}
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
      minH={400}
      height={400}
      borderRadius={14}
      p={'24px 12px'}
      {...props}
      direction={'column'}
      alignItems={'center'}
    >
      <link rel='stylesheet' href={`${process.env.REACT_APP_API_URL}/${layout.cssPath}`} />

      {layout.role.toLocaleLowerCase() === 'introduction' && (
        <Heading
          as='h2'
          fontFamily={LayoutHelper.getFontFamily(template.titleFont)}
          fontWeight={LayoutHelper.getFontWeight(template.titleFont)}
          className='loadingHeading'
          color={getTextColor(template, layout.usesDarkMode)}
        >
          {sender?.company}{' '}
          <FormattedMessage
            description='layoutPageLoadingTitleMessage'
            defaultMessage='a un présent pour vous'
          />
        </Heading>
      )}

      <Box className={'item-container'}>
        {renderItems.map((renderItem: RenderItem) => (
          <TextOrPicture
            key={renderItem.id}
            item={renderItem}
            template={template}
            layout={layout}
            index={renderItem.id}
          />
        ))}
      </Box>

      <Flex
        margin='auto 0px 0px 0px'
        p='8px'
        className='logoArea'
        filter={layout.usesDarkMode ? 'grayscale() brightness(75%) invert(1)' : ''}
      >
        <Image src={`${process.env.REACT_APP_API_URL}/public_assets/-1/pictures/-1/logo.png`} />
      </Flex>
    </Flex>
  )
}
