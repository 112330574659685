import React from 'react'
import { Box, BoxProps, Flex, Image } from '@chakra-ui/react'
import { Layout, RenderItem } from '../domain/layout'
import { getRealPicturePath, getTextColor, Template } from '../domain/template'
import { EditTextOrPicture } from './EditTextOrPicture'
import { TextOrPicture } from './TextOrPicture'
import useProjectStore from '../stores/projectStore'
import { Text } from '../domain/text'
import { LayoutHelper } from '../helpers/layoutHelper'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useEditLayoutUseCase from '../useCases/editLayoutUseCase'

export type EditLayoutCardProps = BoxProps & {
  template: Template
  layout: Layout
  index?: number
}

export default function EditLayoutCard({
  template,
  layout,
  index,
  ...props
}: EditLayoutCardProps): React.JSX.Element {
  const layoutClient = useProjectStore(SlicesHelper.getLayoutSlice)
  const {
    // edit picture or text
    picturesCount,
    containerSizeByPictureCount,
    renderItems,
    updateLayoutItemError,
    uploadPictureError,

    refreshRenderItem,
    toggleEditStatus
  } = useEditLayoutUseCase(layoutClient, layout)

  return (
    <Flex
      className={`layout-card ${layout.role.toLocaleLowerCase()} ${
        picturesCount > 0 ? 'img-container' : undefined
      } ${picturesCount > 0 ? containerSizeByPictureCount[picturesCount - 1] : undefined} edit`}
      backgroundImage={`url("${process.env.REACT_APP_API_URL}/${getRealPicturePath(
        template,
        layout.usesDarkMode
      )}")`}
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
      minH={400}
      height={400}
      borderRadius={14}
      {...props}
      key={index}
      direction={'column'}
      alignItems={'center'}
    >
      <link rel='stylesheet' href={`${process.env.REACT_APP_API_URL}/${layout.cssPath}`} />

      <Box className={'item-container'}>
        {renderItems.map((renderItem: RenderItem, idx) => {
          const text = renderItem as Text
          return !renderItem.isEditable && text === null ? (
            <TextOrPicture
              key={renderItem.id}
              item={renderItem}
              template={template}
              layout={layout}
              index={idx}
              onItemClicked={async (newItem: RenderItem) => {
                await refreshRenderItem(newItem)
                toggleEditStatus(newItem.id)
              }}
            />
          ) : (
            <EditTextOrPicture
              key={renderItem.id}
              item={renderItem}
              fontFamily={
                (text !== null && LayoutHelper.computeFontFamily(template, text.level, false)) ||
                undefined
              }
              fontWeight={
                (text !== null && LayoutHelper.computeFontWeight(template, text.level, false)) ||
                undefined
              }
              fontSize={
                (text !== null && LayoutHelper.computeFontSize(template, text.level, false)) ||
                undefined
              }
              color={(text !== null && getTextColor(template, layout.usesDarkMode)) || undefined}
              onItemValidated={async (newItem: RenderItem) => {
                await refreshRenderItem(newItem)
                if (newItem.type === 'text') {
                  toggleEditStatus(newItem.id)
                }
              }}
              onBlur={() => toggleEditStatus(renderItem.id)}
            />
          )
        })}
      </Box>

      {updateLayoutItemError && <Flex color='red.500'>{updateLayoutItemError}</Flex>}
      {uploadPictureError && <Flex color='red.500'>{uploadPictureError}</Flex>}

      <Flex
        margin='auto 0px 0px 0px'
        p='8px'
        className='logoArea'
        filter={layout.usesDarkMode ? 'grayscale() brightness(75%) invert(1)' : ''}
      >
        <Image src={`${process.env.REACT_APP_API_URL}/public_assets/-1/pictures/-1/logo.png`} />
      </Flex>
    </Flex>
  )
}
